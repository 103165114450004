import { LOCALSTORAGE_KEY, AZFN_GET_GRAPH_THUMBNAIL } from "../common/constants"
import axios from "axios"
import localStorage from "localStorage"
import { DateTime } from "luxon"

export const getThumbnail = async (resource) => {
  const localStorageKey = `${LOCALSTORAGE_KEY.THUMBNAIL}_${resource.id}`
  
  const cachedValue = localStorage.getItem(localStorageKey)
  if (cachedValue) {
    const cachedObject = JSON.parse(cachedValue);
    const cachedTimestamp = DateTime.fromISO(cachedObject.timestamp)
    const cachedThumbnail = cachedObject.thumbnail
    const cachedModified = cachedObject.lastModifiedDateTime
    const expired = DateTime.now().diff(cachedTimestamp, 'minutes').minutes >= 60
    const modified = cachedModified !== resource.lastModifiedDateTime

    if (!modified && !expired)
      return cachedThumbnail
  }

  let thumbnail = ""
  await axios.post(`${AZFN_GET_GRAPH_THUMBNAIL}`, {
    filename: resource.title,
  })
  .then((response) => {
    thumbnail = response.data
  })
  .catch((error) => {
    console.log(error)
  })

  console.log(thumbnail)

  localStorage.setItem(
    localStorageKey,
    JSON.stringify({
      timestamp: DateTime.now().toISO(),
      lastModifiedDateTime: resource.lastModifiedDateTime,
      thumbnail: thumbnail,
    })
  )

  return thumbnail
}
