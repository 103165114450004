import { SESSION_KEY, AZFN_GET_SCHOLAR_FAQs, AZFN_GET_FUTURE_READY_FAQs } from "../common/constants"
import axios from "axios"
import sessionstorage from "sessionstorage"

export const getFAQs = async (type) => {
    let azureFunction = AZFN_GET_SCHOLAR_FAQs
    let sessionStorageKey = SESSION_KEY.SCHOLAR_FAQS
    switch (type) {
        case "future-ready":
            azureFunction = AZFN_GET_FUTURE_READY_FAQs
            sessionStorageKey = SESSION_KEY.FUTURE_READY_FAQS
            break;
        default:
            break;
    }
    let resourceData = []
    let cachedResources = sessionstorage.getItem(sessionStorageKey)
    if (cachedResources) {
        resourceData = JSON.parse(cachedResources)
    } else {
        const response = await axios.get(`${azureFunction}`)
        resourceData = response.data
        sessionstorage.setItem(sessionStorageKey, JSON.stringify(resourceData))
    }
    return resourceData
}