import React from "react"
import PropTypes from "prop-types"
import Footer from "./footer"
import Header from "./header"
import { ToastContainer } from "react-toastify"

export const Layout = ({ children }) => (
  <div className="font-body font-thin">
    <ToastContainer />
    <Header siteTitle={"Northwell Health Community Resource Center"} />
    <main>{children}</main>
    <Footer />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
