import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Footer = () => {
  return (
    <div className="flex px-6 py-4">
      <span>
        <StaticImage
          src="../images/logo.png"
          alt="Northwell Health Logo"
          className="w-8 mr-2"
        />
      </span>
      <span className="text-lg text-nwh-text font-semibold tracking-tight flex items-center">
        Northwell Health
      </span>
    </div>
  )
}

export default Footer
