import React, { useEffect, useState } from "react"
import { ResourceCard, ResourceGrid, NoResults } from "@components"
import { createUltimatePagination } from "react-ultimate-pagination"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAnglesRight,
  faAnglesLeft,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons"

export const ResourcePager = ({ data }) => {
  const perPage = 12

  const [pages, setPages] = useState(0)
  const [pagedData, setPagedData] = useState(data.slice(0, perPage))
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    const totalPages = Math.ceil(data.length / perPage)
    setPages(totalPages)
    updatePage(1)
  }, [data])

  const getPage = pageNumber => {
    const startIndex = (pageNumber - 1) * perPage
    return data.slice(startIndex, startIndex + perPage)
  }

  const Button = ({ value, isActive, disabled, onClick, icon, position }) => (
    <span
      onClick={disabled ? () => {} : onClick}
      className={`btn btn-default inline-block cursor-pointer text-nwh-blue hover:bg-nwh-blue hover:text-white ${
        isActive ? "!text-white !bg-nwh-blue" : ""
      }`}
    >
      {position === "left" && (
        <FontAwesomeIcon icon={icon} className="text-xs mr-2" />
      )}
      <button disabled={disabled} type="button" className={["first","last","previous","next"].includes(value) ? "hidden sm:inline-block" : ""}>
        {value}
      </button>
      {position === "right" && (
        <FontAwesomeIcon icon={icon} className="text-xs ml-2" />
      )}
    </span>
  )

  const PaginatedPage = createUltimatePagination({
    itemTypeToComponent: {
      PAGE: Button,
      ELLIPSIS: () => (
        <span className="mx-2 inline-block cursor-not-allowed text-gray-400">
          ...
        </span>
      ),
      FIRST_PAGE_LINK: () => (
        <Button
          value="first"
          onClick={() => updatePage(1)}
          icon={faAnglesLeft}
          position="left"
        />
      ),
      PREVIOUS_PAGE_LINK: () => (
        <Button
          value="previous"
          onClick={() => {
            if (currentPage > 1) {
              updatePage(currentPage - 1)
            }
          }}
          icon={faAngleLeft}
          position="left"
        />
      ),
      NEXT_PAGE_LINK: () => (
        <Button
          value="next"
          onClick={() => {
            if (currentPage < pages) {
              updatePage(currentPage + 1)
            }
          }}
          icon={faAngleRight}
          position="right"
        />
      ),
      LAST_PAGE_LINK: () => (
        <Button
          value="last"
          onClick={() => updatePage(pages)}
          icon={faAnglesRight}
          position="right"
        />
      ),
    },
  })

  const updatePage = pageNumber => {
    setCurrentPage(pageNumber)
    setPagedData(getPage(pageNumber))
  }

  const currentPagedFirstItemIndex = (currentPage - 1) * perPage + 1
  const currentPagedLastItemIndex =
    currentPagedFirstItemIndex + pagedData.length - 1
  const set = `${currentPagedFirstItemIndex} - ${currentPagedLastItemIndex}`

  return (
    <>
      {data.length > 0 && (
        <div>
          <div className="results-count border-b-[1px] mb-6 pb-6 text-nwh-text text-lg">
            Showing <span className="text-black">{set}</span> of{" "}
            <span className="text-black">{data.length}</span> results
          </div>
          <ResourceGrid>
            {pagedData.map(resource => (
              <ResourceCard {...resource} key={resource.id} />
            ))}
          </ResourceGrid>
        </div>
      )}

      {data.length <= 0 && <NoResults />}
      <div className="pagination mt-2">
        <div className="btn-group">
          {pages > 0 ? (
            <PaginatedPage
              totalPages={pages}
              currentPage={currentPage}
              onChange={page => updatePage(page)}
            />
          ) : null}
        </div>
      </div>
    </>
  )
}
