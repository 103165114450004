import React from "react"
import PropTypes from "prop-types"

export const CenteredLayout = ({ children, className }) => (
  <div className={`flex items-center justify-center py-8 w-full ${className}`}>
    <div className="w-10/12 m-auto">{children}</div>
  </div>
)

CenteredLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
