import { SESSION_KEY, AZFN_GET_EVENTS } from "../common/constants"
import axios from "axios"
import sessionstorage from "sessionstorage"

export const getEvents = async () => {
  let eventsData = []
  let cached = sessionstorage.getItem(SESSION_KEY.EVENTS)
  if (cached) {
    eventsData = JSON.parse(cached)
  } else {
    const response = await axios.get(`${AZFN_GET_EVENTS}`)
    eventsData = response.data
    sessionstorage.setItem(SESSION_KEY.EVENTS, JSON.stringify(eventsData))
  }
  return eventsData
}
