import React from "react"

export const FAQ = ({ faqs }) => {

    return (
        <div>
            {faqs && faqs.map && faqs.map((faq, index) => (
                <React.Fragment key={index}>
                    <h3>{faq.question}</h3>
                    <p>{faq.answer}</p>
                </React.Fragment>
            ))}
        </div>
    )
}

export default FAQ