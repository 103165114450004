import React, { useEffect, useState } from "react"
import { DateTime } from "luxon"
import { RESOURCE_FIELDS, FILTERS_TO_IGNORE} from "../common/constants"
import { toast } from "react-toastify"
import { getThumbnail,  getDownloadUrl } from "@services"
import { Loader } from "@components"
import { isBrowser } from "@utilities"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShareFromSquare } from "@fortawesome/free-regular-svg-icons"

export const ResourceCard = resource => {
  const [thumbnail, setThumbnail] = useState("")
  const [loading, setLoading] = useState(true)

  const modified = DateTime.fromString(
    resource.lastModifiedDateTime,
    RESOURCE_FIELDS.lastModifiedDateTimeFormat
  ).toLocaleString(DateTime.DATETIME_MED)

  const createTags = () => {
    let createdTags = []
    const allResourceKeys = Object.keys(resource)

    for (let i = 0; i < allResourceKeys.length; i++) {
      const currentKey = allResourceKeys[i]
      if (FILTERS_TO_IGNORE.filter(item => currentKey === item).length !== 0) {
        continue
      }
      if (resource[currentKey].length) createdTags.push(resource[currentKey])
    }
    return createdTags
  }
  // if (resource.topic.length) tags.push(resource.topic)
  // if (resource.language.length) tags.push(resource.language)
  // if (resource.createdFor.length) tags.push(resource.createdFor)
  // if (resource.dimensions.length) tags.push(resource.dimensions)
  // if (resource.resourceType.length) tags.push(resource.resourceType)

  const tags = createTags()

  useEffect(() => {
    const loadThumbnail = async () => {
      const thumbnail = await getThumbnail(resource)
      if(!thumbnail) return;
      setThumbnail(thumbnail)
      setLoading(false)
    }
    loadThumbnail()
  }, [resource])

  const copy = async (title) => {
    if (window) {
      const url = getDownloadUrl(title)
      await navigator.clipboard.writeText(url);
      toast.success("Link copied!")
    }
  }

  return (
    <div
      className="relative w-full min-h-[232px] border-[1px] bg-white shadow-md cursor-pointer group hover:shadow-lg hover:scale-105 transition-transform"
      onClick={async () => {
        if (!isBrowser){
          return
        }
        if (!!resource.videoLink) {
          window.open(resource.videoLink)
        }
        else {
          window.open(getDownloadUrl(resource.title))
        }
      }}
    >
      <div onClick={(e) => { e.stopPropagation(); copy(resource.title) }} className="absolute top-2 right-2 rounded-sm z-50 bg-nwh-dark-blue text-white py-2 px-2 flex align-middle items-center"><FontAwesomeIcon icon={faShareFromSquare}></FontAwesomeIcon></div>
      <div className="h-[200px] overflow-hidden">
        {loading && (
          <Loader />
        )}
        {!loading && (
          <img
            className="group-hover:scale-105 group-hover:transition-transform"
            src={thumbnail}
            loading="lazy"
          />
        )}
      </div>
      <div className="px-3 py-3">
        <div className="text-nwh-blue text-lg break-words hover:text-nwh-dark-blue mb-1">
          {resource.title}
        </div>
        {tags.length > 0 && (
          <div className="tags text-sm text-nwh-text">
            Tags: {tags.join(", ")}
          </div>
        )}
        <div className="modified text-sm text-nwh-text">{modified}</div>
      </div>
    </div>
  )
}
