//export const baseUrl = "http://localhost:7071"
export const baseUrl = "https://nwhcommunityresourcesfunctions.azurewebsites.net"
export const GET_RESOURCES_BASE_URL = `${baseUrl}/api/GetResources?type=`
export const GET_FAQS_BASE_URL = `${baseUrl}/api/GetFAQs?type=`
export const AZFN_GET_COMMUNITY_RESOURCES = `${GET_RESOURCES_BASE_URL}Community`
export const AZFN_GET_COMMUNITY_SCHOLAR_RESOURCES = `${GET_RESOURCES_BASE_URL}CommunityScholar`
export const AZFN_GET_FUTURE_READY_RESOURCES = `${GET_RESOURCES_BASE_URL}FutureReady`
export const AZFN_GET_SCHOLAR_FAQs = `${GET_FAQS_BASE_URL}Scholar`
export const AZFN_GET_FUTURE_READY_FAQs = `${GET_FAQS_BASE_URL}FutureReady`
export const AZFN_GET_DOWNLOAD = `${baseUrl}/api/Download`
export const AZFN_GET_GRAPH_THUMBNAIL = `${baseUrl}/api/GetGraphThumbnail`
export const AZFN_GET_EVENTS = `${baseUrl}/api/GetEvents`

export const RESOURCE_FIELDS = {
    lastModifiedDateTime: "lastModifiedDateTime",
    lastModifiedDateTimeFormat: "M/d/yyyy h:mm:ss a"
}

export const FILTERS_TO_IGNORE = [
    "filePath",
    "id",
    "lastModifiedDateTime",
    "thumbnail",
    "title",
    "videoLink"
]

export const EVENT_FIELDS = {
    eventDateTimeFormat: "M/d/yyyy h:mm:ss a"
}

export const SORT_DIRECTION = {
    asc: "asc",
    desc: "desc"
} 

export const SESSION_KEY = {
    COMMUNITY_RESOURCES: "nwh_community_resources",
    COMMUNITY_SCHOLAR_RESOURCES: "nwh_community_scholar_resources",
    FUTURE_READY_RESOURCES: "nwh_future_ready_resources",
    SCHOLAR_FAQS: "nwh_scholar_faqs",
    FUTURE_READY_FAQS: "nwh_future_ready_faqs",
    EVENTS: "nwh_events"
}

export const LOCALSTORAGE_KEY = {
    THUMBNAIL: "nwh_thumbnail"
}

export const SCHOLAR_PROG_KEY ="NCS-SCHOLAR"

export const FUTURE_READY_KEY = "NWH-FUTUREREADY"