import { faCircleNotch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

export const Loader = ({label, color}) => {
  const spinnerColor = color ? color : "text-nwh-blue";
  return <div className="w-full h-full flex flex-col justify-center items-center text-4xl text-center">
    {label && (
      <div className={`text-base ${spinnerColor} pb-2`}>{label}</div> 
    )}
    <div>
       <FontAwesomeIcon icon={faCircleNotch} className={`${spinnerColor} animate-spin`} />
    </div>
  </div>
}
