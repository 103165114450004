import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import React from "react"
import { Menu } from "@components"
import { Link } from "gatsby"

function Header({ siteTitle }) {
  return (
    <div className="">
      <Link to="/"> 
        <div className="flex items-center flex-shrink-0 text-nwh-text px-6 py-4">
          <span>
            <StaticImage
              src="../images/logo.png"
              alt="Northwell Health Logo"
              className="w-16 mr-2"
            />
          </span>
          <span className="text-2xl text-nwh-text font-semibold tracking-tight">
            {siteTitle}
          </span>
        </div>
      </Link>
      <div className="border-y-[1px] sm:px-8 sm:py-3">
        <Menu />
      </div>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
