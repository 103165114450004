import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons"

export const SearchBar = ({ onSearch, className }) => {
  const [active, setActive] = useState(false)
  const [searchText, setSearchText] = useState("")

  const handleKey = (e) => {
    if(e.key === "Enter") onSearch(searchText)
  }

  const handleClear = (e) => {
    setSearchText("")
    onSearch("")
  }

  return (
    <div className={`flex ${className}`}>
      <div className="mx-2 grow">
        <div className={`border-[2px] flex items-center bg-white ${active ? "border-nwh-yellow" : ""} `}>
          <input
            type="text"
            value={searchText}
            onChange={e => {
              setSearchText(e.target.value)
            }}
            onKeyDown={handleKey}
            className="grow px-5 py-4 w-full outline-none"
            onClick={() => setActive(true)}
            onBlur={() => setActive(false)}
          />
          {searchText.length > 0 && (
            <FontAwesomeIcon className="shrink mr-4 text-xl text-nwh-text cursor-pointer" icon={faClose} onClick={handleClear}/>
          )}
        </div>
      </div>
      <div className="mx-2 flex-shrink">
        <div
          onClick={() => onSearch(searchText)}
          className="bg-nwh-blue hover:bg-nwh-dark-blue text-white flex h-full w-[100px] items-center justify-center cursor-pointer"
        >
          <FontAwesomeIcon icon={faSearch} className="text-xl" />
        </div>
      </div>
    </div>
  )
}
