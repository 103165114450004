import React from "react"

export const NoResults = () => (
  <div className="flex align-middle justify-center py-8">
    <div className="text-left w-9/12">
      <div className="text-xl">No results match your search</div>
      <div className="text-lg mb-4">Suggestions</div>
      <ul className="list-disc ml-12 text-lg">
        <li>Make sure spelling is correct</li>
        <li>Try different or broader keywords</li>
      </ul>
    </div>
  </div>
)
