import { SESSION_KEY, AZFN_GET_COMMUNITY_RESOURCES, AZFN_GET_COMMUNITY_SCHOLAR_RESOURCES, AZFN_GET_FUTURE_READY_RESOURCES } from "../common/constants"
import axios from "axios"
import sessionstorage from "sessionstorage"

export const getResources = async (type) => {
  let azureFunction = AZFN_GET_COMMUNITY_RESOURCES
  let sessionStorageKey = SESSION_KEY.COMMUNITY_RESOURCES
  switch (type) {
    case "community-scholar":
      azureFunction = AZFN_GET_COMMUNITY_SCHOLAR_RESOURCES
      sessionStorageKey = SESSION_KEY.COMMUNITY_SCHOLAR_RESOURCES
      break;
    case "future-ready":
      azureFunction = AZFN_GET_FUTURE_READY_RESOURCES
      sessionStorageKey = SESSION_KEY.FUTURE_READY_RESOURCES
      break;
    default:
      break;
  }
  let resourceData = []
  let cachedResources = sessionstorage.getItem(sessionStorageKey)
  if (cachedResources) {
    resourceData = JSON.parse(cachedResources)
  } else {
    const response = await axios.get(`${azureFunction}`)
    resourceData = response.data
    sessionstorage.setItem(sessionStorageKey, JSON.stringify(resourceData))
  }
  return resourceData
}
