export const buildFilter = (filterArray, filterProp) => {
  let filtered = []

  filterArray.forEach(item => {
    if (item[filterProp].length > 0) {
      const found = filtered.find(f => f == item[filterProp])
      if (!found) filtered.push(item[filterProp])
    }
  })

  return filtered.sort().map(filter => ({
    value: filter,
    label: filter,
  }))
}
