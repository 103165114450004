import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"

export const Button = props => {
  return (
    <Link to={props.link}>
      <div className="py-3 px-6 bg-white text-nwh-blue text-sm">
        <FontAwesomeIcon icon={props.icon} className="-ml-2 mr-2" />
        {props.title}
      </div>
    </Link>
  )
}
