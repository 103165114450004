import React from "react"
import PropTypes from "prop-types"

export const ResourceGrid = ({ children }) => (
  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 py-6 w-full">
    {children}
  </div>
)

ResourceGrid.propTypes = {
  children: PropTypes.node.isRequired,
}
