import React, { useEffect, useState } from "react"
import { buildFilter } from "@utilities"
import Select from "react-dropdown-select"

export const ResourceFilters = ({ allResources, filterKeys, onChange }) => {
  const [resourcesFilter, setResourcesFilter] = useState({});

  useEffect(() => {
    onChange(resourcesFilter)
  }, [resourcesFilter])

  const filterFieldRenderer = ({ props, state }) => {
    let values = state.values.map(value => value.label).join(", ")
    return (
      <input
        className="react-dropdown-select-input w-full"
        size={13}
        type="text"
        readOnly
        placeholder={props.placeholder}
        value={values}
      ></input>
    )
  }

  const separateWords = (filterString) => {
    let newString = filterString[0].toUpperCase()
    for (let i = 1; i < filterString.length; i++) {
      const currentCharacter = filterString[i]
      if (currentCharacter === currentCharacter.toUpperCase()) {
        newString += ` ${currentCharacter}`
      }
      else {
        newString += currentCharacter
      }
    }
    return newString
  }

  const createFilterOptions = () => {
    let filterOptions = {}
    let htmlElements = []
    if (allResources.length === 0) return htmlElements

    for (let i = 0; i < filterKeys.length; i++) {
      const currentKey = filterKeys[i]
      filterOptions[currentKey] = buildFilter(allResources, currentKey)
    }

    for (let i = 0; i < filterKeys.length; i++) {
      const filter = filterKeys[i]
      if (filterOptions[filter].length === 0) continue;
      htmlElements.push(
        <div key={filter} className="mx-2 w-1/2 sm:w-auto mb-2 flex-grow">
          <Select
            placeholder={separateWords(filter)}
            clearable={!!resourcesFilter[filter] ? resourcesFilter[filter].length > 0 : false}
            dropdownHandle={!!resourcesFilter[filter] ? resourcesFilter[filter].length <= 0 : false}
            multi
            options={filterOptions[filter]}
            contentRenderer={filterFieldRenderer}
            onChange={values => {
              setResourcesFilter(resourcesFilter => ({
                ...resourcesFilter,
                [filter]: values
              }))
            }}
          />
        </div>
      )
    }
    return htmlElements;
  }

  return (
    <div className="filters flex flex-wrap justify-center">
      {createFilterOptions()}
    </div>
  )
}
