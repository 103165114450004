import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { trimUrl, isBrowser } from "@utilities"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"

const menuItems = [
  {
    label: "Home",
    to: "/",
  },
  {
    label: "All Community Resources",
    to: "/community-resources",
  },
  {
    label: "Events",
    to: "https://www.northwell.edu/education-and-resources/community-engagement/community-relations/community-events",
    target: "_blank",
  },
  {
    label: "Northwell Community Scholars",
    to: "/coupon-verification",
  },
  {
    label: "Human Trafficking",
    to: "/human-trafficking",
  },
  {
    label: "Future Ready NYC",
    to: "/coupon-verification",
  },
]

export const Menu = () => {
  const [open, setOpen] = useState(false)
  const [pathname, setPathname] = useState("")

  useEffect(() => {
    if (isBrowser) setPathname(window.location.pathname)
  }, [])

  return (
    <>
      <div
        className="sm:hidden cursor-pointer bg-nwh-blue text-white w-10 h-10 inline-flex justify-center items-center"
        onClick={() => {
          setOpen(!open)
        }}
      >
        <FontAwesomeIcon icon={faBars} />
      </div>
      <nav className="flex flex-wrap">
        {menuItems.map((menuItem, i) => {
          const selected = trimUrl(pathname) === trimUrl(menuItem.to)
          return (
            <a
              key={i}
              className={`my-2 px-4 text-nwh-text hover:text-nwh-blue cursor-pointer ${
                selected ? "!text-nwh-blue" : ""
                } 
              ${
                open
                  ? "block w-full sm:w-auto first:mt-4 first:sm:mt-2 last:mb-4 last:sm:mb-2"
                  : "hidden sm:block"
                }`}
              target={menuItem.target ? menuItem.target : "_self"}
              rel={menuItem.target === "_blank" ? "noopener noreferrer" : ""}
              onClick={() => {
                if ("_blank" === menuItem.target)
                  window.open(menuItem.to, "", "width=700,height=2000")
                else window.location.href = menuItem.to
              }}
            >
              {menuItem.label}
            </a>
          )
        })}
      </nav>
    </>
  )
}
