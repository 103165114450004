import { faClock, faSquareArrowUpRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React from 'react'
import { EVENT_FIELDS } from '../common/constants';

export const EventCard = event => {
  const start = DateTime.fromFormat(event.startDateTime, EVENT_FIELDS.eventDateTimeFormat)
  return (
    <div className='flex border-b-[1px] py-6 mt-6 last:border-b-0'>
        <div className='flex-col grow'>
            <div className='text-2xl grow text-nwh-blue hover:text-nwh-dark-blue mb-2'><a target="_blank" href={event.link}>{event.title}<FontAwesomeIcon className='ml-2 text-xl' icon={faSquareArrowUpRight}/></a></div>
            <div className='text-nwh-text'>{event.description}</div>
        </div>
        <div className='flex-col shrink'>
            <div className="bg-nwh-light-gray py-8 px-10 text-nwh-text">
                <div className='uppercase text-center text-3xl'>{start.toFormat("MMM")}</div>
                <div className='text-center text-5xl border-b-[1px] pb-2 mb-2'>{start.toFormat("dd")}</div>
                <div className='text-center text-lg'><FontAwesomeIcon icon={faClock} className="mr-2"/>{start.toFormat("h:mm a")}</div>
            </div>
        </div>
    </div>
  )
}